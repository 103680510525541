const MIN_PASSWORD_LENGTH = 10

export default function verifyPassword(password1, password2) {
  const passwordMatch = password1 === password2 && password1
  const passwordLength = password1 && password1.length >= MIN_PASSWORD_LENGTH

  const passwordHasAlphas = /[A-Za-z]/.test(password1)
  const passwordHasNumbers = /\d/.test(password1)
  const passwordHasNonalphas = /\W/.test(password1)

  const passwordRulesMet =
    passwordMatch &&
    passwordLength &&
    passwordHasAlphas &&
    passwordHasNumbers &&
    passwordHasNonalphas

  let showErrorMessage
  if (password1 && password1.length) {
    if (!passwordHasNumbers) showErrorMessage = 'A number is required.'
    else if (!passwordHasAlphas) showErrorMessage = 'A letter is required.'
    else if (!passwordHasNonalphas)
      showErrorMessage = 'A special character is required.'
    else if (!passwordLength)
      showErrorMessage = 'Must have 10 characters or more.'
  }

  return {
    passwordMatch,
    passwordLength,
    passwordHasAlphas,
    passwordHasNumbers,
    passwordHasNonalphas,
    passwordRulesMet,
    showErrorMessage,
  }
}
