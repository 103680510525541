import { User } from '@api/__gen__/gql'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { Box } from '@mui/material'
import Button from 'app/components/Button/Button'
import CardTitle from 'app/components/CardTitle/CardTitle'
import Success from 'app/components/Icons/Success'
import TextField from 'app/components/TextField/TextField'
import verifyPassword from 'app/helpers/verifyPassword'
import { useResetPassword } from 'app/packages/core/api/login/mutations/useResetPassword'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

const VALID_JWT = /^[-_\w]+\.[-_\w]+\.[-_\w]+$/i

const sharedIconStyles = {
  width: '20px',
  height: '20px',
  paddingRight: '7px',
}

const CheckedStatusIcon = styled(Success)(({ theme }) => ({
  ...sharedIconStyles,
  color: theme.colors.primary,
}))

const UncheckedStatusIcon = styled(RadioButtonUncheckedIcon)(({ theme }) => ({
  ...sharedIconStyles,
  color: theme.colors.secondary,
}))

function isValidToken(token: string) {
  if (VALID_JWT.test(token)) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]))
      const now = new Date()
      const exp = new Date(payload.exp * 1000)
      // @ts-ignore
      return exp - now > 0
    } catch (err) {
      return false
    }
  }

  return false
}

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`

function renderRequirement(label: string, isChecked: boolean) {
  return (
    <StyledFlex aria-label={isChecked ? 'checked' : 'unchecked'}>
      {isChecked ? <CheckedStatusIcon /> : <UncheckedStatusIcon />}
      {label}
    </StyledFlex>
  )
}

interface ConfirmationProps {
  title: string
  body: string
  cta?: React.ReactNode
}

export function Confirmation({ title, body, cta }: ConfirmationProps) {
  return (
    <>
      <Box fontSize={16} fontWeight="fontWeightBold" mb={1}>
        {title}
      </Box>
      <Box>{body}</Box>
      {cta && <Box mt={2}>{cta}</Box>}
    </>
  )
}

interface Props {
  token: string
  onSuccess: (user: Pick<User, 'accessRole'>) => unknown
}

export default function PasswordReset(props: Props) {
  const { onSuccess, token } = props

  const location = useLocation()
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [passwordFieldFocused, setPasswordFieldFocused] = useState(false)
  const [submitInProgress, setSubmitInProgress] = useState(false)

  const [resetPassword, { loading, error, data }] = useResetPassword()

  const {
    passwordMatch,
    passwordLength,
    passwordHasAlphas,
    passwordHasNumbers,
    passwordHasNonalphas,
    passwordRulesMet,
    showErrorMessage,
  } = verifyPassword(password, passwordConfirm)

  useEffect(() => {
    if (!loading && data?.resetPassword) {
      onSuccess(data.resetPassword.user)
    }
  }, [loading, data, onSuccess])

  const resendURL = location.pathname.includes('/welcome')
    ? '/resend-invitation'
    : '/forgot-password'

  const ConfirmationLinkExpired = () => (
    <Confirmation
      title="Link Expired"
      body="It looks like that link has expired, but we can send a new one to your inbox."
      cta={<Button href={resendURL}>Take me there</Button>}
    />
  )

  if (!isValidToken(token)) {
    return <ConfirmationLinkExpired />
  }

  if (!loading && error) {
    const code = error?.graphQLErrors?.[0]?.extensions?.code
    if (code === 'PASSWORD_COMPLEXITY_NOT_MET') {
      return (
        <Confirmation
          title="Password Invalid"
          body="We weren’t able to save your new password, because password complexity is not met. Refresh the page to try again."
        />
      )
    } else if (code === 'EXPIRED') {
      return <ConfirmationLinkExpired />
    }

    return (
      <Confirmation
        title="Something went wrong"
        body="We weren’t able to save your new password. Refresh the page to try again."
      />
    )
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault()
        setSubmitInProgress(true)
        await resetPassword(token, password)
      }}>
      <CardTitle>Set password</CardTitle>
      <Box>
        <TextField
          fullWidth
          type="password"
          label="Password"
          value={password}
          error={!passwordFieldFocused && !!showErrorMessage}
          helperText={!passwordFieldFocused && showErrorMessage}
          onFocus={() => setPasswordFieldFocused(true)}
          onBlur={() => setPasswordFieldFocused(false)}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Box>
      <Box>
        <TextField
          fullWidth
          type="password"
          label="Confirm Password"
          value={passwordConfirm}
          helperText={
            !showErrorMessage &&
            !passwordMatch &&
            passwordConfirm.length !== 0 &&
            'Passwords don’t match.'
          }
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />
      </Box>
      <Box mt={2}>
        <Button
          type="submit"
          disabled={!passwordRulesMet || submitInProgress}
          loading={submitInProgress}>
          Save password
        </Button>
      </Box>

      <Box mt={4} mb={1}>
        {renderRequirement('Minimum of 10 characters.', passwordLength)}
        {renderRequirement('At least one number.', passwordHasNumbers)}
        {renderRequirement('At least one letter.', passwordHasAlphas)}
        {renderRequirement(
          'At least one special character (e.g. @, #, $, %, &).',
          passwordHasNonalphas,
        )}
      </Box>
    </form>
  )
}
