import { createTheme } from '@mui/material'
import type {} from '@mui/x-data-grid-premium/themeAugmentation'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'
import type {} from '@mui/x-data-grid/themeAugmentation'

export const colorPalette = {
  caution: '#FFB257', // Carrot 300
  error: '#C32A2F', // Radish 700
  highlight_01: '#EEFFD5', // Avocado 050
  highlight_02: '#FFA3A9', // Radish 100
  highlight_03: '#BDC4FC', // Blueberry 050
  highlight_04: '#CCADF5', // Eggplant 100
  highlight_05: '#5555DD', // Blueberry 500
  highlight_06: '#EEFF00', // Lemon 300
  highlight_07: '#F5F6FF', // Blueberry 025
  highlight_08: '#D4F6A3', // Avocado 200
  highlight_09: '#FF9C29', // Carrot 500
  neutral_01: '#121212', // Granite 900
  neutral_02: '#313131', // Granite 700
  neutral_03: '#4F4F4F', // Granite 500
  neutral_04: '#B2B2B2', // Granite 400
  neutral_05: '#C8C8C8', // Granite 300
  neutral_06: '#DDDDDD', // Granite 200
  neutral_07: '#F3F3F3', // Granite 100
  neutral_08: '#FFFFFF', // Granite 050
  neutral_09: '#003100', // Avocado 900
  neutral_10: '#004500', // Avocado 800
  primary: '#005900', // Avocado 700
  secondary: '#313131', // Granite 700
  validation: '#297E22', // Avocado 600
  text_primary: '#313131', // Granite 700
}

const BASE_REM_TO_PX = 16
export const toRem = (px: number) => `${px / BASE_REM_TO_PX}rem`

export function getTheme() {
  return createTheme({
    colors: colorPalette,
    fontSizes: {
      xs: '0.75rem', // 12px
      sm: '0.875rem', // 14px
      md: '1rem', // 16px
      lg: '1.125rem', // 18px
      xl: '1.25rem', // 20px
      '2xl': '1.5rem', // 24px
      '3xl': '2rem', // 32px
      '4xl': '2.5rem', // 40px
    },
    palette: {
      text: {
        primary: colorPalette.text_primary,
      },
    },
    typography: {
      fontFamily: 'Grotesque',
    },
    transitions: {
      duration: {
        enteringScreen: 500,
        leavingScreen: 400,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            minHeight: '100vh',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '2px',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: colorPalette.neutral_03,
            },
            '&:active .MuiOutlinedInput-notchedOutline': {
              borderColor: colorPalette.primary,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:active .MuiOutlinedInput-notchedOutline': {
              borderColor: colorPalette.primary,
              borderWidth: '1px',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: colorPalette.error,
              color: colorPalette.neutral_02,
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: colorPalette.neutral_06,
              borderWidth: '1px',
              pointerEvents: 'none',
            },
            '& .MuiSvgIcon-root': {
              fill: colorPalette.secondary,
              right: '8px',
              '&.Mui-disabled': {
                fill: colorPalette.neutral_06,
              },
            },
          },
          input: {
            borderColor: colorPalette.neutral_05,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: colorPalette.secondary,
            transition: 'none',

            '&.Mui-focused': {
              color: colorPalette.secondary,
            },

            '&.Mui-disabled': {
              color: colorPalette.neutral_02,
              pointerEvents: 'none',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: colorPalette.secondary,
            margin: 0,
            padding: 0,
            '&:hover': {
              background: 'transparent',
            },
            '&.Mui-checked, &.Mui-checked + span': {
              color: colorPalette.secondary,
            },
            '&.Mui-focusVisible': {
              outline: `2px solid ${colorPalette.primary}`,
              outlineOffset: '2px',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              backgroundColor: colorPalette.neutral_05,
            },
            '& .MuiPickersDay-root': {
              color: colorPalette.secondary,
            },
            '& .MuiPickersDay-root:hover:not(.Mui-selected), .PrivatePickersYear-root :hover:not(.Mui-selected)': {
              backgroundColor: colorPalette.neutral_07,
            },
            '& .MuiPickersDay-root:active:not(.Mui-selected), .PrivatePickersYear-root :active:not(.Mui-selected)': {
              backgroundColor: colorPalette.neutral_06,
            },
            '& .MuiPickersDay-root.Mui-disabled, .PrivatePickersYear-root.Mui-disabled': {
              color: colorPalette.neutral_05,
            },
            '& .MuiPickersDay-root:not(.Mui-selected)': {
              borderColor: colorPalette.neutral_05,
            },
            '&&&': {
              '.MuiPickersDay-root.Mui-selected, .PrivatePickersYear-root .Mui-selected': {
                backgroundColor: colorPalette.primary,
                color: colorPalette.neutral_08,

                '&:hover': {
                  backgroundColor: colorPalette.neutral_10,
                  color: colorPalette.neutral_08,
                },
                '&:active': {
                  backgroundColor: colorPalette.neutral_09,
                },
              },
            },

            '& .MuiClock-root': {
              '& .MuiClock-pin, & .MuiClockPointer-root, & .MuiClockPointer-thumb': {
                backgroundColor: colorPalette.primary,
              },
              '& .MuiClockPointer-thumb': {
                borderColor: colorPalette.primary,
              },
              '& .MuiClockNumber-root:not(.Mui-selected):not(.Mui-disabled)': {
                color: colorPalette.secondary,
              },
              '& .MuiClock-squareMask': {
                backgroundColor: colorPalette.neutral_07,
              },
              '& .MuiButtonBase-root': {
                backgroundColor: 'transparent',
                color: colorPalette.secondary,
                fontWeight: 'bold',
                padding: '8px 8px 6px',

                '&:hover': {
                  backgroundColor: colorPalette.neutral_07,
                },

                '&:active': {
                  backgroundColor: colorPalette.neutral_06,
                },

                '&:focus:not(:focus-visible)': {
                  backgroundColor: colorPalette.primary,
                  color: colorPalette.neutral_08,
                },
              },
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '& .MuiTab-root': {
              color: colorPalette.secondary,
            },
            '& .Mui-selected': {
              color: colorPalette.primary,
            },
          },
          indicator: {
            backgroundColor: colorPalette.primary,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&:hover, &.Mui-selected:hover': {
              backgroundColor: colorPalette.neutral_07,
            },
            '&&&:active, &&&.Mui-selected': {
              backgroundColor: colorPalette.neutral_06,
            },
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: colorPalette.primary,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            margin: 16,
          },
          container: {
            ':focus': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 24,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            paddingBottom: 0,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            paddingTop: 24,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            transition: 'none',
            '&.Mui-error': {
              color: colorPalette.neutral_02,
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
          variant: 'outlined',
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardError: {
            '& .MuiAlert-icon': {
              color: colorPalette.error,
            },
          },
          standardInfo: {
            '& .MuiAlert-icon': {
              color: colorPalette.secondary,
            },
          },
          standardSuccess: {
            '& .MuiAlert-icon': {
              color: colorPalette.validation,
            },
          },
          standardWarning: {
            '& .MuiAlert-icon': {
              color: colorPalette.caution,
            },
            '& .MuiAlert-message': {
              color: colorPalette.secondary,
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.fontSize === 'xs' && {
              fontSize: '1rem',
            }),
            ...(ownerState.fontSize === 'sm' && {
              fontSize: '1.25rem',
            }),
            ...(ownerState.fontSize === 'md' && {
              fontSize: '1.5rem',
            }),
            ...(ownerState.fontSize === 'lg' && {
              fontSize: '1.75rem',
            }),
            ...(ownerState.fontSize === 'xl' && {
              fontSize: '2rem',
            }),
          }),
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: colorPalette.neutral_08,
            borderRadius: '4px',
            boxShadow: '1px 1px 6px rgba(79, 79, 79, 0.5)',
            color: colorPalette.secondary,
            fontSize: '1rem',
            padding: '16px',
          },
          arrow: {
            color: colorPalette.neutral_08,
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          'cell--textLeft': {
            display: 'flex',
            alignItems: 'center',
          },
          // Header container
          columnHeaders: {
            backgroundColor: colorPalette.neutral_07, // Fill rest of header outside cells
            borderBottom: `1px solid ${colorPalette.neutral_05}`,
          },
          columnHeader: {
            fontWeight: 'bold',
            textTransform: 'capitalize',
            backgroundColor: colorPalette.neutral_07, // Fill pinned column headers
          },
          // Aggregation footer cell styling (row showing the sum of all rows)
          footerCell: {
            fontWeight: 'bold',
            color: colorPalette.text_primary,
          },
          // Borders of cells, column headers, but not root.
          withBorderColor: {
            borderColor: colorPalette.neutral_05,
          },
          row: {
            borderBottom: `1px solid ${colorPalette.neutral_05}`,
          },
          root: {
            borderRadius: '4px',
            borderColor: colorPalette.neutral_05,
          },
        },
      },
    },
  })
}
