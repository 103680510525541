import { AppBar } from '@mui/material'
import { Box } from '@mui/system'
import useGetProductBundles, {
  PRODUCT_BUNDLE_CONFIG_MAP,
} from 'app/api/queries/useGetProductBundles'
import CustomerSelector from 'app/components/CustomerSelector/CustomerSelector'
import { ENVIRONMENTS, ROLES } from 'app/constants'
import useToggleState from 'app/hooks/useToggleState'
import { PRODUCT_BUNDLE_TYPE } from 'app/layouts/Customer/AppSideNav/AppSideNav.constants'

import { useVars } from 'app/VarsContext'
import Button from 'app/components/Button/Button'
import EnvironmentLabel from 'app/components/EnvironmentLabel/EnvironmentLabel'
import FallbackLoader from 'app/components/FallbackLoader/FallbackLoader'
import Expand from 'app/components/Icons/Expand'
import { $activeBusinessLevel } from 'app/layouts/Customer/CustomerPageLayout.variables'
import Main from 'app/layouts/Main'
import { useAuthState } from 'app/state/authentication'
import { getTheme } from 'app/styles'
import React, { Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import AppSideNav from './AppSideNav/AppSideNav'

const primarySideNavWidth = 72
const secondarySideNavWidth = 240
const drawerWidth = primarySideNavWidth + secondarySideNavWidth

const StyledExpand = styled(Expand)`
  color: ${({ theme }) => theme.colors.neutral_03};
`

const InternalTopNav = styled(AppBar)<{ open: boolean }>`
  display: flex;
  flex-direction: row;
  height: ${({ theme }) => theme.typography.pxToRem(56)};
  justify-content: flex-start;
  align-items: center;
  box-shadow: none;
  color: ${({ theme }) => theme.colors.secondary};
  z-index: ${({ theme }) => theme.zIndex.appBar + 1};
  padding-left: ${({ theme }) => theme.typography.pxToRem(50)};
  background-color: ${({ theme }) => theme.colors.neutral_08};
  border-radius: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_06}`};
  width: calc(100% - ${primarySideNavWidth}px);
  transition: ${({ theme }) =>
    `${theme.transitions.create(['width', 'margin', 'padding-left'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen,
    })}`};

  ${({ open, theme }) =>
    open &&
    `
    margin-left: ${drawerWidth};
    width: calc(100% - ${drawerWidth}px);
    padding-left: ${theme.typography.pxToRem(26)};
    transition: ${theme.transitions.create(
      ['width', 'margin', 'padding-left'],
      {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen,
      },
    )};
  `}
`

const CustomerSelectorWithMargin = styled(CustomerSelector)`
  margin-left: ${({ theme }) => theme.typography.pxToRem(8)};
`

const DrawerOpenButton = styled(Button)`
  position: absolute;
  height: ${({ theme }) => theme.typography.pxToRem(104)};
  display: flex;
  align-items: flex-start;
  z-index: ${({ theme }) => theme.zIndex.appBar + 2};
  padding: 0px;
  top: ${({ theme }) => theme.typography.pxToRem(12)};
  margin-left: ${({ theme }) => theme.typography.pxToRem(12)};

  :focus-visible {
    height: 24px;
    width: 24px;
  }
`

export interface Props {
  children?: React.ReactNode
}

interface LayoutContextObject {
  hasTopBar: boolean
  sideNavOpen: boolean
  closeTransitioning: boolean
  openTransitioning: boolean
}

export const LayoutContext = React.createContext<LayoutContextObject>({
  hasTopBar: false,
  sideNavOpen: true,
  closeTransitioning: false,
  openTransitioning: false,
})

const OuterContainer = styled(Box)`
  width: 100%;
  overflow-x: auto;
`

const FlexContainer = styled(Box)`
  display: flex;
`

export default function CustomerPageLayout({ children }: Props) {
  const authState = useAuthState()
  const environmentVars = useVars()
  const {
    activeProductBundle,
    productBundles,
    loading: productBundlesLoading,
  } = useGetProductBundles()
  const [openTransitioning, setOpenTransitioning] = useState(false)
  const [closeTransitioning, setCloseTransitioning] = useState(false)

  const [
    sideNavOpen,
    { toggle: toggleSideNav, setClose, setOpen },
  ] = useToggleState(true)

  // Set business level to first in list when product bundle changes, and first load
  useEffect(() => {
    if (activeProductBundle?.businessLevelInstances?.length) {
      $activeBusinessLevel(activeProductBundle?.businessLevelInstances[0].value)
    } else {
      // TODO: change back to null once ___business_level_instances is populated for
      // every customer in prod.
      $activeBusinessLevel('1:39:1:CHAIN:-1')
    }
  }, [activeProductBundle])

  // Open side nav by default for Store Solutions and Distro, close for Inventory Results
  useEffect(() => {
    if (
      productBundles?.length === 0 ||
      (activeProductBundle &&
        !PRODUCT_BUNDLE_CONFIG_MAP[activeProductBundle.type].showSecondaryNav)
    ) {
      setClose()
    }
    if (
      activeProductBundle?.type === PRODUCT_BUNDLE_TYPE.DISTRIBUTION_CENTER ||
      activeProductBundle?.type === PRODUCT_BUNDLE_TYPE.STORE_OPERATIONS
    ) {
      setOpen()
    }
  }, [productBundles, activeProductBundle, setClose, setOpen])

  const showTopBar = authState.accessRole === ROLES.AFRESH_ADMIN
  const sideNavAvailable =
    productBundles &&
    productBundles.length > 0 &&
    activeProductBundle?.type !== PRODUCT_BUNDLE_TYPE.INVENTORY_RESULTS

  const handleDrawerOpen = () => {
    toggleSideNav()
    setOpenTransitioning(true)
    setTimeout(() => {
      setOpenTransitioning(false)
    }, getTheme().transitions.duration.leavingScreen + 100)
  }

  return (
    <LayoutContext.Provider
      value={{
        hasTopBar: showTopBar,
        sideNavOpen,
        closeTransitioning,
        openTransitioning,
      }}>
      <OuterContainer>
        <FlexContainer>
          <Box>
            <AppSideNav
              showTopBar={showTopBar}
              sideNavOpen={sideNavOpen}
              openTransitioning={openTransitioning}
              toggleSideNav={toggleSideNav}
              activeProductBundle={activeProductBundle?.type}
              productBundles={productBundles}
              closeTransitioning={closeTransitioning}
              setCloseTransitioning={setCloseTransitioning}
            />
          </Box>
          <Box>
            {!sideNavOpen && sideNavAvailable && (
              <DrawerOpenButton variant="icon" onClick={handleDrawerOpen}>
                <StyledExpand />
              </DrawerOpenButton>
            )}
          </Box>
          {showTopBar && (
            <InternalTopNav open={sideNavOpen}>
              {environmentVars.environment === ENVIRONMENTS.STAGING && (
                <EnvironmentLabel env={ENVIRONMENTS.STAGING} />
              )}
              {environmentVars.environment === ENVIRONMENTS.PRODUCTION && (
                <EnvironmentLabel env={ENVIRONMENTS.PRODUCTION} />
              )}
              {environmentVars.environment === ENVIRONMENTS.PRODUCTION ||
              environmentVars.environment === ENVIRONMENTS.STAGING ? (
                <CustomerSelectorWithMargin />
              ) : (
                <CustomerSelector />
              )}
            </InternalTopNav>
          )}
          <Main
            $showTopBar={showTopBar}
            $open={sideNavOpen}
            $hideSideNav={!sideNavAvailable}>
            {productBundlesLoading && <FallbackLoader />}
            {!productBundlesLoading && (
              <Suspense fallback={<FallbackLoader />}>
                {children || <Outlet />}
              </Suspense>
            )}
          </Main>
        </FlexContainer>
      </OuterContainer>
    </LayoutContext.Provider>
  )
}
